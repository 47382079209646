
import { Component, Vue } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { Office, OfficePatchPayload, OfficePatch } from "@/store/modules/office.store";

@Component({
    components: {
        Spinner: () => import("@/components/general/spinner.vue"),
        OfficeForm: () => import("./parts/office-form.vue"),
    },
})
export default class PageAdminOfficesShow extends Vue {
    @Getter("office/viewing") viewingOffice!: Office;

    @Action("office/clearViewing") clearViewing!: () => void;

    @Action("office/patch") updateOffice!: OfficePatch;

    submitting = false;

    submit(payload: OfficePatchPayload) {
        this.submitting = true;

        this.updateOffice(payload!).then(() => {
            this.submitting = false;
            this.$toast.open({ message: this.$t("views.settings.admin-offices.update_success") as string, type: "success", position: "bottom-right" });
        });
    }
}
